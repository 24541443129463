import { useMutation, useQueryClient } from "@tanstack/react-query";
import { isObject } from "lodash";
import { useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { ScormCourseContent } from "../scorm-support/scorm-course-content";
import { isExamUnlockedOrCourseCompleted } from "../utils/course.utils";
import { CourseProgressState } from "./course-progress.enum";
import {
  cascadeCourseProgressUpdate,
  updateCourseProgress,
} from "./course-progress.service";
import { CourseProgress } from "./course-progress.types";

const KEY_SUSPEND_DATA = "cmi.suspend_data";
const KEY_LESSON_STATUS = "cmi.core.lesson_status";

let courseWindow: WindowProxy;
let lastCourseId: number | undefined;

export type RunCourseParams = {
  courseId?: number;
  cascadeProgress?: boolean;
  courseProgress?: CourseProgress;
};

export const useRunCourse = ({
  courseId,
  cascadeProgress: cascade,
  courseProgress,
}: RunCourseParams) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  useEffect(() => {
    if (lastCourseId !== courseId) {
      courseWindow?.close();
      lastCourseId = courseId;
      localStorage.removeItem(KEY_SUSPEND_DATA);
      localStorage.removeItem(KEY_LESSON_STATUS);
    }
  }, [courseId]);

  useEffect(() => {
    if (courseProgress) {
      localStorage.setItem(KEY_SUSPEND_DATA, courseProgress.position);
    }
  }, [courseProgress]);

  const { mutate: mutateCourseProgress } = useMutation({
    mutationFn: ({
      state,
      position,
    }: {
      state: CourseProgressState;
      position: string;
    }) => {
      if (!courseId) throw new Error("Course ID is required");
      return cascade
        ? cascadeCourseProgressUpdate(courseId, { state, position })
        : updateCourseProgress(courseId, { state, position });
    },
    mutationKey: ["courseProgress", courseId],
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["courseProgress"] });
      queryClient.invalidateQueries({ queryKey: ["childProgresses"] });
    },
  });

  const openCourseWindow = (
    title: string,
    courseUrl: string,
    reset?: boolean,
  ) => {
    if (!courseId) throw new Error("Course ID is required");
    const wnd = window.open(
      "",
      "_blank",
      "fullscreen=yes|rel=opener",
    ) as WindowProxy;
    wnd.document.write(ScormCourseContent(courseUrl));
    wnd.document.title = title;
    wnd.onload = () => {
      wnd?.window.postMessage({ ...localStorage });
    };
    const needsProgressUpdate =
      reset || !isExamUnlockedOrCourseCompleted(courseProgress?.state);
    if (needsProgressUpdate) {
      mutateCourseProgress({
        state: CourseProgressState.INPROGRESS,
        position: "started",
      });
    }
    courseWindow = wnd;
  };

  const updateLocalStorage = (event: MessageEvent) => {
    if (!event.data || !isObject(event.data)) return;
    const storableKeys = [KEY_SUSPEND_DATA, KEY_LESSON_STATUS];
    storableKeys.forEach((key) => {
      if (key in event.data) localStorage.setItem(key, event.data[key]);
    });
  };

  const handleMessage = useCallback(
    async (event: MessageEvent) => {
      if (event.data.logout === "logout") {
        courseWindow?.close();
        navigate("/logout");
      }
      if (event.origin !== window.location.origin) return;
      const hasPassed = ["passed", "completed"].includes(
        event.data[KEY_LESSON_STATUS],
      );
      const hasSuspendData = !!event.data[KEY_SUSPEND_DATA];

      if (hasPassed) {
        window.removeEventListener("message", handleMessage, false);
      }

      if (hasPassed || hasSuspendData) {
        mutateCourseProgress({
          state:
            hasPassed ||
            courseProgress?.state === CourseProgressState.FINISHEDWATCHING
              ? CourseProgressState.FINISHEDWATCHING
              : CourseProgressState.INPROGRESS,
          position:
            event.data[KEY_SUSPEND_DATA] ??
            courseProgress?.position ??
            "started",
        });
      }
      updateLocalStorage(event);
    },
    [courseWindow, navigate, mutateCourseProgress, courseProgress],
  );

  useEffect(() => {
    window.addEventListener("message", handleMessage, false);
    return () => window.removeEventListener("message", handleMessage, false);
  }, [handleMessage]);

  return { openCourseWindow };
};
